import React from 'react';
import { Duration } from 'luxon';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import pluralize from 'pluralize';
import { eventPath } from '../../lib/pathUtil';
import { useHistory } from 'react-router-dom';
import { formatDuration, getEventDuration } from '../lib/event';

interface RelativeScheduledDisplayProps {
  teamId: string;
  event: Event;
  predecessorEvent: Event;
  predecessorOffset: Duration;
  from?: 'event' | 'operation';
}

const RelativeScheduledDisplay = ({
  teamId,
  event,
  predecessorEvent,
  predecessorOffset,
  from = 'event',
}: RelativeScheduledDisplayProps) => {
  const history = useHistory();

  const inputDuration = Duration.fromISO(predecessorOffset);
  const timeInMinutes = Number(inputDuration.as('minutes').toFixed(2));

  const navigateToEvent = () => {
    history.push(eventPath(teamId, predecessorEvent.id), { from });
  };

  return (
    <>
      <div className="flex flex-wrap items-center group">
        <span className="inline-block mr-1 break-normal">
          {timeInMinutes} {pluralize('Minutes', timeInMinutes)} After
        </span>
        <span onClick={navigateToEvent} className="inline-block link cursor-pointer break-all">
          {predecessorEvent.name}
        </span>
      </div>
      {event.end && (
        <div className="w-full text-xs text-gray-400 whitespace-normal break-words">
          (Duration: {formatDuration(getEventDuration(event))})
        </div>
      )}
    </>
  );
};

export default RelativeScheduledDisplay;
