import React, { useMemo, useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { calculateEventDuration, calculateNewEndTime, formatDuration } from '../lib/event';
import DurationGenerator from './DurationGenerator';
import { Duration } from 'luxon';

const EventDurationSelect = ({ field, form }) => {
  const [showModal, setShowModal] = useState(false);
  const initialDraft = field.value || '';
  const [draftDuration, setDraftDuration] = useState<Duration | null>(initialDraft);
  const isEditable = draftDuration || form.values.start || form.values.predecessor_id;
  const { setFieldValue } = form;

  useEffect(() => {
    if (form.values.predecessor_id) {
      return;
    }
    if (form.values.start && form.values.end) {
      const duration = calculateEventDuration(form.values.start, form.values.end);
      setDraftDuration(duration);
      setFieldValue(field.name, duration);
    } else {
      // if start or end time get cleared, clear the duration
      setDraftDuration(null);
      setFieldValue(field.name, null);
    }
  }, [form.values.start, form.values.end, form.values.predecessor_id, setFieldValue, field.name]);

  const handleLabelClick = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setDraftDuration(initialDraft);
    setShowModal(false);
  };

  const handleOK = () => {
    if (form.values.start && !form.values.predecessor_id) {
      const newEnd = calculateNewEndTime(form.values, draftDuration);
      form.setFieldValue('end', newEnd);
    }
    form.setFieldValue(field.name, draftDuration);
    setShowModal(false);
  };

  const isSaveEnabled = useMemo(() => {
    if (!initialDraft && !draftDuration) {
      return false;
    }
    if (!initialDraft && draftDuration) {
      return true;
    }
    // Disable OK button if no change to current duration
    return draftDuration.toMillis() !== initialDraft.toMillis();
  }, [draftDuration, initialDraft]);

  return (
    <div className="flex flex-row items-center">
      {showModal && (
        <Modal
          title="Duration"
          primaryActionTitle="OK"
          secondaryActionTitle="Cancel"
          onPrimaryAction={handleOK}
          onSecondaryAction={handleCancel}
          isPrimaryActionEnabled={isSaveEnabled}
          size="lg"
        >
          <DurationGenerator value={draftDuration} setDraftDuration={setDraftDuration} />
        </Modal>
      )}
      {isEditable && (
        <div
          className="flex flex-row items-center w-fit px-2 h-[42px] rounded border group cursor-pointer bg-white border-gray-400"
          onClick={handleLabelClick}
        >
          {draftDuration ? formatDuration(draftDuration) : 'N/A'}
          <div className="ml-2 p-1 rounded group-hover:bg-gray-200">
            <FontAwesomeIcon className="text-gray-500" icon="edit" />
          </div>
        </div>
      )}
      {!isEditable && (
        <div className="flex flex-row w-fit px-2 h-[42px] rounded border items-center group border-transparent">
          <span className="italic text-gray-400">N/A</span>
        </div>
      )}
    </div>
  );
};

export default EventDurationSelect;
