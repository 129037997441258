import { isString } from 'lodash';
import { DateTime, Duration } from 'luxon';
import pluralize from 'pluralize';
import { FrontendEvent as Event } from 'shared/schedule/types/event';

interface NestedEvents {
  [key: string]: {
    [key: string]: {
      [key: string]: Array<Event>;
    };
  };
}

export interface EventFilters {
  keywords?: Array<string>;
  operations: Array<string>;
  no_operation: boolean;
}

export const firstDate = (dates: Array<DateTime>): DateTime =>
  dates.find((date) => date.ts === Math.min.apply(null, dates));

export const lastDate = (dates: Array<DateTime>): DateTime =>
  dates.find((date) => date.ts === Math.max.apply(null, dates));

export const nestEventsByDate = (events: Array<Event>): NestedEvents => {
  const sortedEvents = events.sort(
    (a, b) => (a.start as DateTime) - (b.start as DateTime)
  );
  const nestedEvents = {};
  for (const event of sortedEvents) {
    const d = event.start as DateTime;
    if (!(d.year in nestedEvents)) {
      nestedEvents[d.year] = {};
    }
    if (!(d.month in nestedEvents[d.year])) {
      nestedEvents[d.year][d.month] = {};
    }
    if (!(d.day in nestedEvents[d.year][d.month])) {
      nestedEvents[d.year][d.month][d.day] = [];
    }
    nestedEvents[d.year][d.month][d.day].push(event);
  }

  return nestedEvents;
};

interface EventError {
  name: string;
  end: string;
  start: string;
  rrule: string;
  predecessor_offset: string;
}

export const validateEvent = (event: Event): Partial<EventError> => {
  const errors: Partial<EventError> = {};

  if (!event.name || !event.name.trim()) {
    errors.name = 'Required';
  }

  if (!event.start && event.end) {
    errors.end = 'End time without start';
  }

  if (event.predecessor_id && !event.predecessor_offset) {
    errors.predecessor_offset =
      'Wait time required when predecessor event is set';
  }

  if (event.predecessor_offset) {
    const duration = isString(event.predecessor_offset)
      ? Duration.fromISO(event.predecessor_offset)
      : event.predecessor_offset;
    if (!duration.isValid) {
      errors.predecessor_offset = 'Wait must be in HH:MM:SS format';
    }

    if (duration > Duration.fromObject({ days: 1 })) {
      errors.predecessor_offset = 'Wait cannot exceed 24 hours';
    }
  }

  if (event.rrule && (!event.start || !event.end)) {
    errors.rrule = 'Repeated events must have a start and end date';
  }

  if (event.start && event.end && event.start >= event.end) {
    errors.start = 'Start date must be earlier than end date';
  }

  if (event.milestone && !event.start) {
    errors.start = 'Milestone must have start time';
  }

  return errors;
};

export const calculateEventDuration = (
  start: DateTime | undefined,
  end: DateTime | undefined
): Duration | null => {
  if (!(start instanceof DateTime) || !(end instanceof DateTime)) {
    return null;
  }
  return end.diff(start);
};

export const getEventDuration = (event: Event): Duration => {
  return calculateEventDuration(event.start, event.end);
};

export const calculateNewEndTime = (
  event: Event,
  duration: Duration
): DateTime => {
  return (event.start as DateTime).plus(duration);
};

export const formatDuration = (duration: Duration): string => {
  const days = Math.floor(duration.as('days'));
  const hours = Math.floor(duration.as('hours') % 24);
  const minutes = Math.floor(duration.as('minutes') % 60);
  const seconds = Math.floor(duration.as('seconds') % 60);

  const parts: string[] = [];

  if (days > 0) {
    parts.push(`${days} ${pluralize('days', days)}`);
  }
  if (hours > 0) {
    parts.push(`${hours} ${pluralize('hours', hours)}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} ${pluralize('minutes', minutes)}`);
  }
  if (seconds > 0) {
    parts.push(`${seconds} ${pluralize('seconds', seconds)}`);
  }
  const output = parts.join(', ');
  return output ? output : '0 seconds';
};

type DurationTimeUnits = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

// Converts a Luxon Duration to an object with days, hours, minutes, and seconds.
export const durationToTimeUnits = (duration: Duration): DurationTimeUnits => {
  const shifted = duration.shiftTo('days', 'hours', 'minutes', 'seconds');

  const days = shifted.days ?? 0;
  const hours = shifted.hours ?? 0;
  const minutes = shifted.minutes ?? 0;
  const seconds = shifted.seconds ?? 0;
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const defaultDurationTimeUnits = (): DurationTimeUnits => {
  return {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
};

export const eventMatchesFilters = (
  event: Event,
  filters: EventFilters
): boolean => {
  if (filters.keywords) {
    const combined = [event.name].join(' ').toLowerCase();
    for (const keyword of filters.keywords) {
      if (!combined.includes(keyword)) {
        return false;
      }
    }
  }

  if (
    // The event has an operation not included in the filter list
    (event.operation?.name &&
      !filters.operations.includes(event.operation.name)) ||
    // The event has no operation and only events with operations are to be shown
    (!event.operation?.name && !filters.no_operation)
  ) {
    return false;
  }

  return true;
};
